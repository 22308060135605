import React, { useEffect, useState } from "react"
import algoliasearch from "algoliasearch/lite"
import { usePropertyStore } from "../../components/SearchResults/store/propertyStore"
import { SEOSEARCHRESULTS } from "../../components/seo-search-results"
import useSearchResultsSEO from "../../hooks/useSearchResultsSEO"
import SearchResultsTemplate from "../../templates/property-results"
import createResultsUrl from "../../utils/property-results/createResultsUrl"
import generateFiltersfromPageData from "../../utils/property-results/generateFiltersfromPageData"
import parseResultsUrl from "../../utils/property-results/parseResultsUrl"
// const { useSearchResultsSEO } = require("@starberry/gatsby-library-common/PropertySearch")
// format h1 & desc and send to template
// format seo component
import isbot from "isbot"
export default function SearchResults(props) {
  const [algoliadata, getAlgoliadata] = useState([])
  const [algoliadataNew, getAlgoliadataNew] = useState([])
  const { reset, clearFilters } = usePropertyStore()
  // const [mysortbyoption, setMysortbyoption] = useState('');
  // const [mypageoption, setMypageoption] = useState('');

  //the current page data are lives here
  const page_url_data = parseResultsUrl(props.location)

  // you can make default search page results filters here
  const myalgoliafilters = generateFiltersfromPageData(
    page_url_data["departmentVal"],
    page_url_data["searchtypeVal"],
    page_url_data["areaVal"],
    page_url_data["bedVal"],
    page_url_data["bathVal"],
    page_url_data["minpriceVal"],
    page_url_data["maxpriceVal"],
    page_url_data["statusVal"],
    page_url_data["buildingval"],
    page_url_data["amenities"],
    page_url_data["minsqftVal"],
    page_url_data["maxsqftVal"],
    page_url_data["newdevelopmentsVal"],
    page_url_data["isResidentialOnly"],
    page_url_data["completionVal"]
  )

  //
  const myalgoliafiltersNew = generateFiltersfromPageData(
    page_url_data["departmentVal"],
    page_url_data["searchtypeVal"],
    page_url_data["areaVal"],
    page_url_data["bedVal"],
    page_url_data["bathVal"],
    page_url_data["minpriceVal"],
    page_url_data["maxpriceVal"],
    page_url_data["statusVal"],
    page_url_data["buildingval"],
    page_url_data["amenities"],
    page_url_data["minsqftVal"],
    page_url_data["maxsqftVal"],
    page_url_data["newdevelopmentsVal"],
    page_url_data["isResidentialOnly"],
    page_url_data["completionVal"],
    page_url_data["offMarket"]
    
  )

  // navigate to pages based on filters

  const { pageh1, introcopy } = useSearchResultsSEO(
    page_url_data["departmentVal"],
    page_url_data["searchtypeVal"],
    page_url_data["areaVal"],
    page_url_data["bedVal"],
    page_url_data["bathVal"],
    page_url_data["minpriceVal"],
    page_url_data["maxpriceVal"],
    page_url_data["statusVal"],
    page_url_data["buildingval"],
    page_url_data["amenities"],
    page_url_data["minsqftVal"],
    page_url_data["maxsqftVal"],
    page_url_data["newdevelopmentsVal"]
  )

  let realuser = true

  useEffect(() => {
    // getAlgoliaResutls(myalgoliafilters)
    if (typeof window !== "undefined") {
      realuser =
        process.env.GATSBY_BlOCK_ALGOLIA_BOT_REQUEST == "true"
          ? isbot(navigator.userAgent)
          : false
          
    }
    if (realuser === false) {
      getAlgoliaResutls(myalgoliafilters)
      getAlgoliaResutlsNew(myalgoliafiltersNew)
    } else {
      // getBotSearchResult()
    }
    if (
      props?.location?.pathname ===
        "/properties/for-sale/in-" +
          process.env.GATSBY_DEFAULT_AREA.toLowerCase() +
          "/" ||
      props?.location?.pathname ===
        "/properties/to-rent/in-" +
          process.env.GATSBY_DEFAULT_AREA.toLowerCase() +
          "/"
    ) {
      reset()
      clearFilters()
    }
  }, [props.location])
  
  const getFreeText=typeof window!=="undefined"? sessionStorage.getItem("isfreetext"):""

  const getAlgoliaResutls = async myalgoliafilters => {
    // lets run algolia search query to fetch hits, stats and number of pages
    const client = algoliasearch(
      process.env.GATSBY_ALGOLIA_APP_ID,
      process.env.GATSBY_ALGOLIA_API_KEY
    )
    const index = client.initIndex(
      page_url_data["sortVal"]
        ? page_url_data["sortVal"]
        : page_url_data["indexVal"]
    )
    
    await index
      .search((!getFreeText?"":page_url_data['areaVal'] == (process.env.GATSBY_DEFAULT_AREA).toLowerCase() ? '' : page_url_data['areaVal']), {
        // similarQuery: myareaquery != process.env.GATSBY_DEFAULT_AREA ? myareaquery.join(' ') : '',
        filters: myalgoliafilters,
        page: page_url_data["pageVal"] - 1,
        hitsPerPage: page_url_data["layoutVal"] ? 1000 : 8,
      })
      .then(({ nbHits, page, hitsPerPage, nbPages, hits }) => {
        var myArray = {
          total: nbHits,
          current_page_number: page,
          hitsPerPage: hitsPerPage,
          number_of_pages: nbPages,
          hits: hits,
        }
        getAlgoliadata(myArray)
      })
      
  }

  const getAlgoliaResutlsNew =async myalgoliafiltersNew => {
    // lets run algolia search query to fetch hits, stats and number of pages
    const client = algoliasearch(
      process.env.GATSBY_ALGOLIA_APP_ID,
      process.env.GATSBY_ALGOLIA_API_KEY
    )
    const index = client.initIndex(
      page_url_data["sortVal"]
        ? page_url_data["sortVal"]
        : page_url_data["indexVal"]
    )
    
   await index
      .search((!getFreeText?"":page_url_data['areaVal'] == (process.env.GATSBY_DEFAULT_AREA).toLowerCase() ? '' : page_url_data['areaVal']), {
        filters: myalgoliafiltersNew,
        page: page_url_data["pageVal"] - 1,
        hitsPerPage: page_url_data["layoutVal"] ? 1 : 1,
      })
      .then(({ nbHits, page, hitsPerPage, nbPages, hits }) => {
        var myArray = {
          total: nbHits,
          current_page_number: page,
          hitsPerPage: hitsPerPage,
          number_of_pages: nbPages,
          hits: hits,
        }
        getAlgoliadataNew(myArray)
      })
      
  }

  return (
    <React.Fragment>
      <SearchResultsTemplate
        {...algoliadata}
        // offMarketData = {algoliadataNew?.hits}
        offMarketData = ""
        offmarketCount = {algoliadataNew?.total}
        pageh1={pageh1}
        introcopy={introcopy}
        page_url_data={page_url_data}
        createResultsUrl={createResultsUrl}
        location_path={props.location.pathname}
        indexname={page_url_data["indexVal"]}
        getAlgoliadata={getAlgoliadata}
        realuser={typeof window !== "undefined" && isbot(navigator.userAgent)}
      />
    </React.Fragment>
  )
}

export const Head = props => {
  //the current page data are lives here
  const page_url_data = parseResultsUrl(props.location)

  // you can make default search page results filters here
  const myalgoliafilters = generateFiltersfromPageData(
    page_url_data["departmentVal"],
    page_url_data["searchtypeVal"],
    page_url_data["areaVal"],
    page_url_data["bedVal"],
    page_url_data["minpriceVal"],
    page_url_data["maxpriceVal"],
    page_url_data["statusVal"],
    page_url_data["buildingval"],
    page_url_data["amenities"],
    page_url_data["minsqftVal"],
    page_url_data["maxsqftVal"],
    page_url_data["newdevelopmentsVal"]
  )

  const { pagetitle, pagemetadesc } = useSearchResultsSEO(
    page_url_data["departmentVal"],
    page_url_data["searchtypeVal"],
    page_url_data["areaVal"],
    page_url_data["bedVal"],
    page_url_data["bathVal"],
    page_url_data["minpriceVal"],
    page_url_data["maxpriceVal"],
    page_url_data["statusVal"],
    page_url_data["buildingval"],
    page_url_data["amenities"],
    page_url_data["minsqftVal"],
    page_url_data["maxsqftVal"],
    page_url_data["newdevelopmentsVal"]
  )

  return (
    <SEOSEARCHRESULTS
      title={`${pagetitle} | ${process.env.GATSBY_SITE_NAME}`}
      description={pagemetadesc}
      url={props.location.pathname}
    />
  )
}